<template>
  <div class="app-container">
    <!-- <div class="filter-container">
      <el-input v-model="listQuery.name" placeholder="请输学生姓名" style="width: 200px;" class="filter-item" clearable/>
      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
    </div> -->
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      max-height="600"
      highlight-current-row
    >
      <el-table-column label="序号" :min-width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="教师名称" :min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.teacher.name }}
        </template>
      </el-table-column>
      <el-table-column label="教学课程" :min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school_course.name }}
        </template>
      </el-table-column>
      <el-table-column label="教学简介" :min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.teacher.synopsis }}
        </template>
      </el-table-column>
      <el-table-column label="教师邮箱" :min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.teacher.email }}
        </template>
      </el-table-column>
      <el-table-column label="联系电话" :min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.teacher.phone }}
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10,
        name: null,
        class_id: null,
        type: 2
      },
      list: [],
      total: null,
      listLoading: false,
      form: {
        id: "",
      },
    };
  },
  created() {
    this.listQuery.class_id = this.$route.query.class_id;
    this.getList();
  },
  methods: {
    handleCheck() {
      this.$router.push(`/second/signup`);
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/eduAdmin/courseList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
